import backend from 'apiUtils/backend'
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds'
import { isProduction } from 'helpers/constants'
import { InfiniteMembershipsByOwnerResponse } from 'interfaces/interfaces'
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

/**
 *
 * @param address of User
 * @returns Returns Array of Memberships that user created
 */
const useMembershipsByOwner = (address: string): UseInfiniteQueryResult<InfiniteMembershipsByOwnerResponse> => {
	return useInfiniteQuery(
		['memberships-by-owner', address],
		() =>
			backend
				.get<InfiniteMembershipsByOwnerResponse>(`/membership/owner/${address}/memberships`, {
					params: { showTestnet: !isProduction },
				})
				.then((res) => res.data),
		{
			getNextPageParam: (lastPage) => lastPage.skip,
			retry: false,
			staleTime: minutesToMilliseconds(5),
			refetchInterval: minutesToMilliseconds(5),
			refetchOnWindowFocus: true,
			enabled: !!address,
		}
	)
}

export default useMembershipsByOwner
