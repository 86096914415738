import useProfile from '../useProfile'
import useMembershipsByOwner from './useMembershipsByOwner'

const useMembershipsByOwnerLength = (): number => {
	const { data: profile } = useProfile()
	const { data, isSuccess } = useMembershipsByOwner(profile?.user.wallet_address)

	if (!isSuccess) return 0

	return data.pages.map((item) => item.data).flat().length
}

export default useMembershipsByOwnerLength
