import { Erc20__factory } from '@contracts/abis/types'
import { getAddress, isAddress } from 'ethers/lib/utils'
import { ethPsuedoAddress } from 'helpers/constants'
import { checkIsEth, parseTokenSymbol } from 'helpers/utils'
import { useQuery, UseQueryResult } from 'react-query'
import { shortenAddressWithNoDots } from 'utils'
import useProvider, { ProviderType } from './useProvider'

interface Input {
	address?: string
	chainId?: number
	onlySymbol?: boolean
	defaultValue?: string
}

/**
 *
 * @param input Token Address
 * @returns Token display name
 * - If address is ethPsuedoAddress, returns 'ETH' or 'MATIC'
 * - If address is a valid address, returns token symbol or name
 * - If address is not a valid address, returns address (which can perhaps be something else)
 */
export const useTokenDisplayName = ({
	address,
	chainId,
	onlySymbol = false,
	defaultValue,
}: Input = {}): UseQueryResult<string> => {
	const provider = useProvider(ProviderType.ReadOnly, chainId)

	return useQuery(
		['token-display-name', { address, chainId, onlySymbol }],
		async () => {
			if (defaultValue) return defaultValue
			if (!isAddress(address)) return address
			const _address = getAddress(address)
			if (_address === ethPsuedoAddress) return checkIsEth(chainId) ? 'ETH' : 'MATIC'

			const tokenContract = Erc20__factory.connect(_address, provider)

			if (onlySymbol) return tokenContract.symbol()

			const [symbolData, nameData] = await Promise.allSettled([tokenContract.symbol(), tokenContract.name()])
			if (symbolData.status === 'fulfilled') return parseTokenSymbol(symbolData.value)
			else if (nameData.status === 'fulfilled') return nameData.value
			else return shortenAddressWithNoDots(_address)
		},
		{
			enabled: Boolean(address && chainId && provider),
			staleTime: Infinity,
			cacheTime: Infinity,
			placeholderData: () => {
				if (defaultValue) return defaultValue
				if (onlySymbol || !address) return null
				if (address === ethPsuedoAddress) return 'ETH'
				return shortenAddressWithNoDots(address)
			},
			retry: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	)
}
