import { ethPsuedoAddress } from 'helpers/constants'
import { useQuery, UseQueryResult } from 'react-query'
import { isAddress } from 'utils'
import { GetContractTypeEnum } from 'utils/getContract'
import { useTokenContract } from './useContract'

/**
 * @param tokenAddress Address of the token
 * @param existingDecimalValue Value if already known -- example: from DB
 */
const useTokenDecimals = (
	tokenAddress?: string,
	existingDecimalValue?: number,
	chainId?: number
): UseQueryResult<number> => {
	const tokenContract = useTokenContract(tokenAddress, GetContractTypeEnum.ReadOnly, chainId)
	const isNativeToken = tokenAddress === ethPsuedoAddress

	return useQuery(
		['token-decimals', tokenAddress],
		async () => {
			if (existingDecimalValue) return existingDecimalValue
			if (isNativeToken) return 18
			if (!isAddress(tokenAddress)) throw new Error(`Invalid token address: ${tokenAddress}`)
			else if (!tokenContract) throw new Error('No token contract found')

			return tokenContract.decimals()
		},
		{
			enabled: Boolean(tokenAddress && (!isNativeToken ? tokenContract : true)),
			cacheTime: Infinity,
			staleTime: Infinity,
			initialData: existingDecimalValue || isNativeToken ? 18 : undefined,
			retry: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	)
}

export default useTokenDecimals
