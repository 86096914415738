import { Button } from '@components/core'
import { LeftArrowIcon } from '@components/icons'
import { useRouter } from 'next/router'
import { FC } from 'react'

const NotFound404: FC<{ text: string }> = ({ text }) => {
	const router = useRouter()
	return (
		<>
			<div className="my-auto bg-color-1 flex flex-col justify-around items-center pt-20 3xs:p-20 gap-10">
				<div className="h-full relative justify-center items-center">
					<SkyGlitterSVG className="fill-color-3" />
					<div className="absolute inset-0 flex justify-center items-center w-full h-full">
						<PortalSVG className="fill-color-2" />
					</div>
					<div className="absolute inset-0 flex justify-center items-center w-full h-full">
						<MagnifyingGlassIcon className="fill-color-4" />
					</div>
				</div>
				<span className="font-semibold text-color-8 text-[18px]">{text}</span>
				{/* Go Back button */}
				<Button className="" variant="outlined" onClick={() => router.back()} size="sm">
					<span className="flex flex-row gap-2 items-center">
						<LeftArrowIcon className="w-4 h-4 stroke-color-6" /> Go Back
					</span>
				</Button>
			</div>
		</>
	)
}

export default NotFound404

const MagnifyingGlassIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.0532 39.802C15.849 34.5978 15.849 26.1601 21.0532 20.9558C26.2574 15.7516 34.6951 15.7516 39.8993 20.9558C45.1036 26.1601 45.1036 34.5978 39.8993 39.802C34.6951 45.0062 26.2574 45.0062 21.0532 39.802ZM15.3963 15.299C7.06792 23.6274 7.06792 37.1304 15.3963 45.4588C22.7643 52.8268 34.1821 53.6765 42.4841 48.008L57.8929 63.1995C59.4661 64.7504 61.9987 64.7324 63.5497 63.1593C65.1006 61.5861 65.0826 59.0535 63.5095 57.5026L48.1343 42.3443C53.7709 34.0458 52.9115 22.6543 45.5562 15.299C37.2278 6.97056 23.7247 6.97057 15.3963 15.299Z"
		/>
	</svg>
)

const SkyGlitterSVG: FC<{ className: string }> = ({ className }) => (
	<svg
		className={className}
		width="404"
		height="341"
		viewBox="0 0 404 341"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="333.052" cy="177.922" r="6" />
		<circle cx="376.605" cy="89.9771" r="8.89705" />
		<circle cx="149.585" cy="62.1648" r="3.69021" />
		<circle cx="309.622" cy="103.495" r="2.69" />
		<circle cx="323.071" cy="305.976" r="2.69" />
		<circle cx="78.0865" cy="223.621" r="2.69" />
		<circle cx="119.632" cy="329.078" r="2.69" />
		<circle cx="60.147" cy="87.2877" r="2.69" />
		<circle cx="215.825" cy="43.6597" r="2.69" />
		<circle cx="317.691" cy="239.746" r="2.69" />
		<circle cx="373.915" cy="151.433" r="2.69" />
		<circle cx="260.341" cy="6.6543" r="6" />
		<circle cx="56.7279" cy="175.05" r="8.87141" />
		<circle cx="80.7764" cy="311.771" r="6" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M286.775 96.3918C286.752 89.8861 281.481 84.6196 274.981 84.6196C281.495 84.6196 286.775 79.3294 286.775 72.8037C286.799 79.3094 292.07 84.5759 298.569 84.5759C292.056 84.5759 286.775 89.8661 286.775 96.3918Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M99.1905 117.979C99.1669 111.473 93.8956 106.207 87.3965 106.207C93.9101 106.207 99.1905 100.916 99.1905 94.3906C99.214 100.896 104.485 106.163 110.985 106.163C104.471 106.163 99.1905 111.453 99.1905 117.979Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M366.462 283.859C366.428 274.431 358.788 266.798 349.369 266.798C358.809 266.798 366.462 259.131 366.462 249.673C366.496 259.102 374.136 266.734 383.556 266.734C374.115 266.734 366.462 274.402 366.462 283.859Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M295.156 331.767C295.145 328.694 292.655 326.207 289.585 326.207C292.662 326.207 295.156 323.708 295.156 320.625C295.167 323.698 297.657 326.186 300.727 326.186C297.65 326.186 295.156 328.685 295.156 331.767Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.661 214.085C27.6499 211.012 25.1599 208.524 22.0898 208.524C25.1667 208.524 27.661 206.025 27.661 202.942C27.6721 206.015 30.1622 208.503 33.2322 208.503C30.1553 208.503 27.661 211.002 27.661 214.085Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M321.48 43.66C321.469 40.5869 318.979 38.0991 315.909 38.0991C318.986 38.0991 321.48 35.6001 321.48 32.5176C321.491 35.5907 323.982 38.0784 327.052 38.0784C323.975 38.0784 321.48 40.5774 321.48 43.66Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M397.63 192.99C397.619 189.917 395.129 187.429 392.059 187.429C395.135 187.429 397.63 184.93 397.63 181.848C397.641 184.921 400.131 187.409 403.201 187.409C400.124 187.409 397.63 189.907 397.63 192.99Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M218.515 340.22C218.503 337.146 216.013 334.659 212.943 334.659C216.02 334.659 218.515 332.16 218.515 329.077C218.526 332.15 221.016 334.638 224.086 334.638C221.009 334.638 218.515 337.137 218.515 340.22Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M188.706 315.08C188.683 308.575 183.411 303.308 176.912 303.308C183.426 303.308 188.706 298.018 188.706 291.492C188.73 297.998 194.001 303.264 200.5 303.264C193.986 303.264 188.706 308.555 188.706 315.08Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.6612 286.424C27.6308 278.052 20.8476 271.275 12.4844 271.275C20.8663 271.275 27.6612 264.468 27.6612 256.07C27.6914 264.442 34.4747 271.219 42.8379 271.219C34.4559 271.219 27.6612 278.027 27.6612 286.424Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M188.706 38.089C188.683 31.5834 183.411 26.3169 176.912 26.3169C183.426 26.3169 188.706 21.0266 188.706 14.501C188.73 21.0066 194.001 26.2731 200.5 26.2731C193.986 26.2731 188.706 31.5634 188.706 38.089Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M86.1752 36.0066C86.1393 26.1034 78.115 18.0864 68.2217 18.0864C78.1371 18.0864 86.1752 10.0333 86.1752 0.0996094C86.2109 10.0029 94.2353 18.0198 104.129 18.0198C94.2131 18.0198 86.1752 26.0729 86.1752 36.0066Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.4845 141.567C12.4609 135.061 7.18959 129.794 0.69043 129.794C7.20409 129.794 12.4845 124.504 12.4845 117.979C12.5079 124.484 17.7793 129.751 24.2785 129.751C17.7647 129.751 12.4845 135.041 12.4845 141.567Z"
		/>
		<circle cx="387.807" cy="317.771" r="4.25138" />
	</svg>
)

const PortalSVG: FC<{ className?: string }> = ({ className }) => (
	<svg
		className={className}
		width="186"
		height="193"
		viewBox="0 0 186 193"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M93.4011 3.05273C69.6752 3.05273 46.9207 12.4778 30.144 29.2545C13.3672 46.0313 3.94238 68.7855 3.94238 92.5115V189.702H182.157V91.9364C182.006 68.4315 172.611 45.9302 156.002 29.2974C139.393 12.6647 116.906 3.23694 93.4011 3.05273Z"
			stroke="url(#paint0_linear_2936_2676)"
			strokeWidth="6"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_2936_2676"
				x1="186.321"
				y1="-25.9815"
				x2="-7.88205"
				y2="-15.833"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E8B05D" />
				<stop offset="0.28125" stopColor="#ED6F4D" />
				<stop offset="0.807292" stopColor="#7765CB" />
				<stop offset="1" stopColor="#4F81E2" />
			</linearGradient>
		</defs>
	</svg>
)
