import { SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { TierCard } from '@components/byPage/memberships/TierCard'
import NotFound404 from '@components/common/NotFound404'
import { Avatar, Button, Pagination } from '@components/core'
import { LeftArrowIcon } from '@components/icons'
import Layout from '@components/Layout/Layout'
import useMembershipsByOwner from '@hooks/query/membership/useMembershipsByOwner'
import useMembershipsByOwnerLength from '@hooks/query/membership/useMembershipsByOwnerLength'
import useProfile from '@hooks/query/useProfile'
import clsx from 'clsx'
import { formatEther } from 'ethers/lib/utils'
import { ethPsuedoAddress } from 'helpers/constants'
import { getProfile } from 'helpers/queryFunctions'
import { getUsername } from 'helpers/utils'
import { GetServerSideProps } from 'next'
import { FC, useState } from 'react'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
	const slug_address = query.profile

	if (!slug_address) {
		return { props: {} }
	}

	if (slug_address.includes('favicon.ico')) {
		return { props: {} }
	}

	const queryClient = new QueryClient()

	await queryClient.prefetchQuery(['profile', slug_address], getProfile)

	return {
		props: { dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))) },
	}
}

const ProfileMemberships: FC = () => {
	const { data: profile } = useProfile()
	const username = profile ? getUsername(profile?.user, true) : ''

	return (
		<>
			<Layout title="Memberships">
				<div className="w-full flex px-5 py-10 lg:p-20 gap-10 flex-col mb-20">
					<Button href={`/${username}`} className="h-[25px] group" variant="outlined" size="sm">
						<div className="flex flex-row items-center">
							<LeftArrowIcon className="stroke-color-6 mr-1 group-active:stroke-color-8 w-4 h-4" />
							Back to Profile
						</div>
					</Button>
					<div className="flex flex-row gap-5 items-center">
						<Avatar
							gradient1={profile?.user?.gradient_1}
							gradient2={profile?.user?.gradient_2}
							profilePicUrl={profile?.user?.profile_pic_url}
						/>
						<span className="text-color-8 text-[25px] lg:text-[40px] font-semibold">Membership Tiers</span>
					</div>
					<Content />
				</div>
			</Layout>
		</>
	)
}

export const Content: FC = () => {
	const { data: profile, isLoading: isProfileLoading } = useProfile()
	const {
		data: userMemberships,
		isLoading: isUserMembershipsLoading,
		isSuccess: isUserMembershipsSuccess,
	} = useMembershipsByOwner(profile?.user.wallet_address)

	const membershipsLength = useMembershipsByOwnerLength()

	if (isProfileLoading || isUserMembershipsLoading || !userMemberships)
		return (
			<div className="flex flex-col lg:flex-row items-center justify-between gap-10 w-full lg:w-[824px] mx-auto">
				{[...Array(2).keys()].map((i) => (
					<div className="sm:px-20 rounded-4xl border border-color-6 p-10 w-full lg:w-[372px] h-[27rem]" key={i}>
						<SkeletonCircle size="14" />
						<SkeletonText mt="4" noOfLines={4} spacing="6" />
					</div>
				))}
			</div>
		)

	if (isUserMembershipsSuccess && userMemberships.pages[0].data.length > 0)
		return (
			<>
				{/* Mobile View */}
				<div className="lg:hidden overflow-auto w-full flex gap-10 lg:gap-20 flex-col lg:flex-row">
					{userMemberships.pages.map((grpMemberships) =>
						grpMemberships.data.map((membership, i) => (
							<TierCard
								key={i}
								membership={{
									address: membership.contractAddress,
									image: membership.image,
									title: membership.title,
									purchaseTokenAddress: ethPsuedoAddress,
									benefits: membership.benefits,
									duration: Number(membership.validity),
									cap: membership.cap,
									quantity: membership.quantity,
									airdrop: {
										amount: membership.airdropAmount,
										tokenAddress: membership.airdropToken,
									},
									network_chain_id: membership.network_chain_id,
									price: Number(formatEther(membership.price)),
								}}
							/>
						))
					)}
				</div>
				{/* Desktop View */}
				<PaginatedTiers pages={membershipsLength}>
					{userMemberships.pages.map((grpMemberships) =>
						grpMemberships.data.map((membership, i) => (
							<TierCard
								className="mr-20"
								key={i}
								fillAvailableHeight
								membership={{
									address: membership.contractAddress,
									image: membership.image,
									title: membership.title,
									purchaseTokenAddress: ethPsuedoAddress,
									benefits: membership.benefits,
									duration: Number(membership.validity),
									cap: membership.cap,
									quantity: membership.quantity,
									airdrop: {
										amount: membership.airdropAmount,
										tokenAddress: membership.airdropToken,
									},
									network_chain_id: membership.network_chain_id,
									price: Number(formatEther(membership.price)),
								}}
							/>
						))
					)}
				</PaginatedTiers>
			</>
		)
	else if (userMemberships?.pages[0].data.length === 0) return <NotFound404 text="No Membership Tier Found" />
}

const PaginatedTiers: FC<{ pages: number }> = ({ pages, children }) => {
	const [index, setIndex] = useState(0)

	const handleNext = (): void => {
		setIndex((i) => (i + 1) % pages)
	}

	const handlePrev = (): void => {
		setIndex((i) => {
			if (i === 0) return ((i - 1) % pages) + pages
			return i - (1 % pages)
		})
	}

	return (
		<div className="w-[824px] hidden lg:flex flex-col mx-auto">
			<div
				className={clsx(
					'overflow-y-auto grid grid-flow-col overflow-hidden w-72 sm:w-96 lg:w-full relative mx-auto',
					pages <= 1 && 'mb-12'
				)}
			>
				{/* Inner Container */}
				<div
					className={clsx(
						'whitespace-nowrap inline-flex transform transition duration-300 ease-in-out justify-center lg:justify-start w-full gap-10 lg:gap-0 flex-col lg:flex-row mb-4'
					)}
					style={{ transform: `translateX(-${(100 / pages) * index}%)` }}
				>
					{/* Cards */}
					{children}
				</div>
			</div>
			{/* Pagination */}
			{pages > 1 && <Pagination index={index} pages={pages} handleNext={handleNext} handlePrev={handlePrev} />}
		</div>
	)
}

export default ProfileMemberships
